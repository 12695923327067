import { useLocation } from "react-router-dom";

import css from "./leftmenu.module.css";
import { AdminBar } from "./AdminBar";

export const LeftMenu = (props) => {
  const { pathname } = useLocation();

  let className = css.container;
  if (!props.isMenuVisible) {
    className = css.collapsed;
  }

  function getSidebar() {
    switch (true) {
      case pathname === "/":
        return <AdminBar />;
      default:
        return <AdminBar />;
    }
  }

  return <div className={className}>{getSidebar()}</div>;
};
