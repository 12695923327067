import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import HTMLReactParser from "html-react-parser";

import css from "./AdminPage.module.css";
import { PostContext } from "../../Context/PostContext";
import { updateListsFirebase } from "../../Utils/firebasePosts";
import { Head } from "../../Utils/Head";
import { Input } from "../Forms/Input";
import OutsideAlerter from "../../Utils/OutsideAlerter";

export const AdminPage = () => {
  const { unitsList, setUnitsList } = useContext(PostContext);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPost, setShowPost] = useState(false);

  function deletePost(post) {
    let updatedItalianList = [...unitsList];
    updatedItalianList = updatedItalianList.filter((p) => p.id !== post.id);

    setUnitsList(updatedItalianList);
    updateListsFirebase("italianLists", updatedItalianList);
    alert(post.title + " deleted");
  }

  return (
    <div>
      <Head title="Admin" description={"Admin - Mairini"} />
      <Input
        type="text"
        name="Buscar"
        placeholder="Title"
        label="Search"
        className={css.inputText}
        autoComplete="off"
      />
      <h1>Italian Units</h1>
      {unitsList.map((post) => (
        <div className={css.card} key={post.id}>
          <div className={css.cardTop}>
            <i
              className={`material-icons materialIcon`}
              onClick={() =>
                navigate("/admin/editpost", {
                  state: {
                    id: post.id,
                    date: post.date,
                    slug: post.slug,
                    language: post.language,
                    level: post.level,
                    image: post.image,
                    title: post.title,
                    lessonContent: post.lessonContent,
                    listeningUrl: post.listeningUrl,
                    listeningContent: post.listeningContent,
                    exercisesSlug: post.exercisesSlug,
                  },
                })
              }
            >
              edit
            </i>
            <i
              className={`material-icons materialIcon`}
              onClick={() => setShowDeleteModal(post)}
            >
              delete
            </i>
          </div>
          <div className={css.cardBody} onClick={() => setShowPost(post)}>
            <h4>{post.title}</h4>
            <p>{post.language}</p>
            <p>{post.level}</p>
          </div>
        </div>
      ))}

      {showPost && (
        <OutsideAlerter setMenuButton={setShowPost}>
          <div className={css.modal}>
            <header className={css.modalHeader}>
              <span
                onClick={() => setShowPost(false)}
                className={`material-icons ${css.materialIcon} ${css.materialButton}`}
              >
                close
              </span>
            </header>
            <div className={css.modalBody}>
              <h3>{showPost.title}</h3>
              <p>Date: {dayjs(showPost.date).format("DD/MM/YY")}</p>
              <p>Language: {showPost.language}</p>
              <p>Level: {showPost.level}</p>
              <hr />
              <p>{HTMLReactParser(showPost.lessonContent)}</p>
              <button
                className="defaultButton"
                onClick={() => setShowPost(false)}
              >
                Close
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
      {showDeleteModal && (
        <OutsideAlerter setMenuButton={setShowDeleteModal}>
          <div className={css.modal}>
            <header className={css.modalHeader}>
              <span
                onClick={() => setShowDeleteModal(false)}
                className={`material-icons ${css.materialIcon} ${css.materialButton}`}
              >
                close
              </span>
            </header>
            <div className={css.modalBody}>
              <h3>Delete {showDeleteModal.title}?</h3>
              <button
                className="deleteButton"
                onClick={(e) => {
                  e.preventDefault();
                  deletePost(showDeleteModal);
                  setShowDeleteModal(false);
                }}
              >
                Deletar
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
};
