import React, { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import css from "./header.module.css";
import { ReactComponent as Logo } from "../assets/vortariLogo.svg";
import { UserContext } from "../Context/UserContext";

export const Header = () => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={css.topbar}>
      <div className={css.topbarWrapper}>
        <div className={css.topLeft}>
          <div className={css.logo}>
            <Link className={css.logo} to="/" aria-label="Mairini - Home">
              <Logo />
              Vortari
            </Link>
          </div>
          <div className={css.tollbar}>
            {/* <Link
              className={
                pathname === "/" || pathname === "/users"
                  ? css.tollbarItensActive
                  : css.tollbarItens
              }
              to="/"
            >
              Início
            </Link>
            <Link
              className={
                pathname === "/schedule"
                  ? css.tollbarItensActive
                  : css.tollbarItens
              }
              to="/schedule"
            >
              Língua Portuguesa
            </Link> */}
            {/* <Link
              className={
                pathname === "/schedule"
                  ? css.tollbarItensActive
                  : css.tollbarItens
              }
              to="/schedule"
            >
              Literatura
            </Link> */}
          </div>
        </div>
        <div className={css.topRight}>
          <div className={css.avatar}>
            <div className={css.menuContainer}>
              {user ? (
                <button
                  className="defaultButton"
                  onClick={() => navigate("/logout")}
                >
                  Sair
                </button>
              ) : (
                <button
                  className="defaultButton"
                  onClick={() => navigate("/login/")}
                >
                  Entrar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
