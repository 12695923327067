import { initializeApp } from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  GoogleAuthProvider,
} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAh_kcW1cuZIPQv2myuS3143WBLUja9XWI",
  authDomain: "vortari.firebaseapp.com",
  projectId: "vortari",
  storageBucket: "vortari.appspot.com",
  messagingSenderId: "590470262582",
  appId: "1:590470262582:web:9074ad76990761ef238d97",
  measurementId: "G-8KR2TP9DBP",
};

const app = initializeApp(firebaseConfig);

if (window.location.hostname === "localhost") {
  var authFirebase = getAuth();
  connectAuthEmulator(authFirebase, "http://127.0.0.1:7099");
  var dbFirebase = getFirestore();
  connectFirestoreEmulator(dbFirebase, "127.0.0.1", 7070);
  var storageFirebase = getStorage();
  connectStorageEmulator(storageFirebase, "localhost", 9199);
} else {
  authFirebase = getAuth(app);
  dbFirebase = getFirestore(app);
  storageFirebase = getStorage(app);
}

export const storage = storageFirebase;
export const auth = authFirebase;
export const db = dbFirebase;
export const googleProvider = new GoogleAuthProvider();
