import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { UserStorage } from "./Context/UserContext";
import { PostStorage } from "./Context/PostContext";
import { Home } from "./Components/Home";
import { Login } from "./Components/Login/Login";
import { Logout } from "./Components/Login/Logout";
import { NewUnit } from "./Components/Admin/NewUnit";
import { EditUnit } from "./Components/Admin/EditUnit";
import { Header } from "./Layout/Header";
import { RequireAuth } from "./Utils/RequireAuth";
import { LeftMenu } from "./Components/Sidebar/LeftMenu";
import { Content } from "./Layout/Content";
import { AdminPage } from "./Components/Admin/AdminPage";
import { Lesson } from "./Components/Unit/Lesson";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const toggleMenu = (e) => {
    setIsMenuVisible(!isMenuVisible);
  };

  const SidebarLayout = () => (
    <>
      <Header onMenuClick={toggleMenu} />
      <LeftMenu isMenuVisible={isMenuVisible} />
      <Content
        isMenuVisible={isMenuVisible}
        setIsMenuVisible={setIsMenuVisible}
      />
    </>
  );

  return (
    <UserStorage>
      <PostStorage>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login/*" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/it/en/:lesson" element={<Lesson />} />
          <Route element={<RequireAuth />}>
            <Route element={<SidebarLayout />}>
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/admin/newpost" element={<NewUnit />} />
              <Route path="/admin/editpost" element={<EditUnit />} />
            </Route>
          </Route>
        </Routes>
      </PostStorage>
    </UserStorage>
  );
}

export default App;
