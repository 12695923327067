import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

import css from "./Lesson.module.css";
import { PostContext } from "../../Context/PostContext";
import { Header } from "../../Layout/Header";

export const Lesson = () => {
  const { unitsList } = useContext(PostContext);
  const params = useParams();
  const [lesson, setLesson] = useState([]);

  useEffect(() => {
    unitsList &&
      setLesson(unitsList[unitsList.map((p) => p.slug).indexOf(params.lesson)]);
  }, [unitsList, params.lesson]);

  if (lesson && lesson.lessonContent) {
    return (
      <div>
        <Header />
        <div className={css.container}>
          <div></div>
          <div>
            <h2>{lesson.title}</h2>
            <p>{HTMLReactParser(lesson.lessonContent)}</p>
          </div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return <>Loading</>;
  }
};
