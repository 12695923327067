import React, { useEffect, useState } from "react";

import { getItalianListsFirebase } from "../Utils/firebasePosts";

export const PostContext = React.createContext();

export const PostStorage = ({ children }) => {
  const [unitsList, setUnitsList] = useState([]);

  // POPULATE Doubts
  useEffect(() => {
    if (unitsList.length === 0) {
      getItalianListsFirebase().then(function (result) {
        if (result) {
          setUnitsList(result.unitsList);
        } else {
          setUnitsList([]);
        }
      });
    }
  }, [unitsList.length]);

  return (
    <PostContext.Provider
      value={{
        unitsList,
        setUnitsList,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
