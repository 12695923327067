import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import css from "./Sidebar.module.css";
import { UserContext } from "../../Context/UserContext";

export const AdminBar = ({ id }) => {
  const { userData } = useContext(UserContext);
  const { pathname } = useLocation();

  if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}>
          <div className={css.sidebarMenu}>
            <h5>{userData.displayName}</h5>
            <ul className={css.sidebarList}>
              {pathname === "/admin" ? (
                <Link to={`/admin`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>home</i>
                    Início
                  </li>
                </Link>
              ) : (
                <Link to={`/admin`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>home</i>
                    Início
                  </li>
                </Link>
              )}

              {pathname === "/admin/newpost" ? (
                <Link to={`/admin/newpost`} className={css.link}>
                  <li className={`${css.sidebarListItem} ${css.active}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      post_add
                    </i>
                    New Unit
                  </li>
                </Link>
              ) : (
                <Link to={`/admin/newpost`} className={css.link}>
                  <li className={`${css.sidebarListItem}`}>
                    <i className={`material-icons ${css.sidebarIcon}`}>
                      post_add
                    </i>
                    New Unit
                  </li>
                </Link>
              )}
            </ul>
          </div>

          <div
            className={`copyright ${
              userData.staff ? css.sidebarMenu : css.copyright
            } `}
          >
            mairini.com © 2025
          </div>
        </div>
      </div>
    );
  } else if (userData) {
    return (
      <div className={css.sidebar}>
        <div className={css.sidebarWrapper}></div>
        <div className={css.sidebarMenu}>
          <h5>..., XX anos</h5>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
