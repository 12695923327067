import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import css from "./Home.module.css";
import { PostContext } from "../Context/PostContext";
import { Head } from "../Utils/Head";
import { Header } from "../Layout/Header";
import { UserContext } from "../Context/UserContext";

export const Home = () => {
  const { user } = useContext(UserContext);
  const { unitsList } = useContext(PostContext);
  const navigate = useNavigate();

  return (
    <>
      <Head title="Home" description="Home - Vortari" />
      <Header />
      <div className={css.container}>
        <div></div>
        <div>
          {/* {user && (
            <button
              className="defaultButton"
              onClick={() => navigate("/admin")}
            >
              Admin
            </button>
          )} */}
          <h2>Free Italian Grammar Lessons</h2>
          {/* <p>Learn languages and cultures</p> */}
          {unitsList.map((unit) => (
            <div
              key={unit.id}
              className={css.card}
              onClick={() => navigate(`it/en/${unit.slug}`)}
            >
              <div className={css.cardImg}>image</div>
              <div className={css.cardText}>
                <p>
                  <strong>{unit.title}</strong>
                </p>
                <p>{unit.level}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
