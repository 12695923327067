import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import HTMLReactParser from "html-react-parser";

import css from "./AdminPage.module.css";
import { PostContext } from "../../Context/PostContext";
import { useLocation, useNavigate } from "react-router-dom";
import { updateListsFirebase } from "../../Utils/firebasePosts";
import { newId } from "../../Utils/newId";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebaseConfig";
import { Input } from "../Forms/Input";
import { InputArea } from "../Forms/InputArea";

export const EditUnit = () => {
  const { unitsList, setUnitsList } = useContext(PostContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [language, setLanguage] = useState("");
  const [level, setLevel] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [lessonContent, setLessonContent] = useState("");
  const [image, setImage] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const [listeningUrl, setListeningUrl] = useState("");
  const [listeningContent, setListeningContent] = useState("");
  const [exercisesSlug, setExercisesSlug] = useState("");
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    if (state) {
      setDate(state.date);
      setLanguage(state.language);
      setLevel(state.level);
      setTitle(state.title);
      setSlug(state.slug);
      setImage(state.image);
      setLessonContent(state.lessonContent);
      setListeningUrl(state.listeningUrl);
      setListeningContent(state.listeningContent);
      setExercisesSlug(state.exercisesSlug);
    }
  }, [state]);

  const slugify = (text) =>
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");

  function handleSubmit() {
    let updatedUnitsList = [];

    const updatedUnit = {
      id: state.id,
      date: date,
      language: language,
      level: level,
      title: title,
      slug: slug,
      image: image,
      lessonContent: lessonContent,
      listeningUrl: listeningUrl,
      listeningContent: listeningContent,
      exercisesSlug: exercisesSlug,
    };

    updatedUnitsList = [...unitsList];
    updatedUnitsList = updatedUnitsList.map((post) =>
      post.id === updatedUnit.id ? updatedUnit : post
    );
    setUnitsList(updatedUnitsList);

    updateListsFirebase("italianLists", updatedUnitsList);
    navigate("/admin");
  }

  const uploadImage = () => {
    const name = newId();
    let imageRef = ref(storage, `postImages/${state.pillar}/${name}`);

    const uploadTask = uploadBytesResumable(imageRef, imageUpload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log(downloadURL);
          setImage(downloadURL);
          alert("Image Uploaded");
        });
      }
    );
  };

  return (
    <div>
      <h4>Edit Unit</h4>
      <p>Date: {dayjs().format("DD/MM/YY")}</p>
      <p>Language</p>
      <div className={css.checkOptions}>
        <label className={css.confirmation}>
          Italian
          <input
            type="checkbox"
            checked={language === "italian" ? true : false}
            onChange={() => {
              setLanguage("italian");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          English
          <input
            type="checkbox"
            checked={language === "english" ? true : false}
            onChange={() => {
              setLanguage("english");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          Portuguese
          <input
            type="checkbox"
            checked={language === "portuguese" ? true : false}
            onChange={() => {
              setLanguage("portuguese");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          Spanish
          <input
            type="checkbox"
            checked={language === "spanish" ? true : false}
            onChange={() => {
              setLanguage("spanish");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
      </div>
      <p>Level</p>
      <div className={css.checkOptions}>
        <label className={css.confirmation}>
          A1
          <input
            type="checkbox"
            checked={level === "A1" ? true : false}
            onChange={() => {
              setLevel("A1");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          A2
          <input
            type="checkbox"
            checked={level === "A2" ? true : false}
            onChange={() => {
              setLevel("A2");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          B1
          <input
            type="checkbox"
            checked={level === "B1" ? true : false}
            onChange={() => {
              setLevel("B1");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          B2
          <input
            type="checkbox"
            checked={level === "B2" ? true : false}
            onChange={() => {
              setLevel("B2");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          C1
          <input
            type="checkbox"
            checked={level === "C1" ? true : false}
            onChange={() => {
              setLevel("C1");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
        <label className={css.confirmation}>
          C2
          <input
            type="checkbox"
            checked={level === "C2" ? true : false}
            onChange={() => {
              setLevel("C2");
            }}
          />
          <span className={css.checkmark}></span>
        </label>
      </div>
      <h2>Lesson</h2>
      <Input
        type="text"
        name="title"
        placeholder="Title"
        label="Title"
        value={title}
        required
        className={css.inputText}
        onChange={(e) => {
          setTitle(e.target.value);
          setSlug(slugify(e.target.value));
        }}
        autoComplete="off"
      />
      <Input
        type="text"
        name="slug"
        placeholder="slug"
        label="Slug"
        value={slug}
        required
        className={css.inputText}
        onChange={(e) => setSlug(e.target.value)}
        autoComplete="off"
      />
      {image && <img alt="" src={image} className={css.image} />}
      <div>
        <input
          type="file"
          onChange={(event) => {
            setImageUpload(event.target.files[0]);
          }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            uploadImage();
          }}
          disabled={progress !== null && progress < 100}
        >
          Upload Image
        </button>
      </div>

      <InputArea
        type="text"
        name="lessonContent"
        placeholder="lessonContent"
        label="lessonContent"
        value={lessonContent}
        required
        className={css.inputText}
        onChange={(e) => setLessonContent(e.target.value)}
        autoComplete="off"
      />
      <p>Preview:</p>
      <hr />

      <div className={css.preview}>{HTMLReactParser(lessonContent)}</div>

      <hr />

      <h2>Listening</h2>
      <Input
        type="text"
        name="listeningUrl"
        placeholder="listeningUrl"
        label="listeningUrl"
        value={listeningUrl}
        required
        className={css.inputText}
        onChange={(e) => setListeningUrl(e.target.value)}
        autoComplete="off"
      />
      <InputArea
        type="text"
        name="listeningContent"
        placeholder="listeningContent"
        label="listeningContent"
        value={listeningContent}
        required
        className={css.inputText}
        onChange={(e) => setListeningContent(e.target.value)}
        autoComplete="off"
      />
      <h2>Exercises</h2>
      <Input
        type="text"
        name="exercisesSlug"
        placeholder="exercisesSlug"
        label="exercisesSlug"
        value={exercisesSlug}
        required
        className={css.inputText}
        onChange={(e) => setExercisesSlug(e.target.value)}
        autoComplete="off"
      />

      <div className={css.footer} onClick={() => handleSubmit()}>
        <button className="saveButton">Save</button>
      </div>
    </div>
  );
};
