import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const updateListsFirebase = async (language, unitsList) => {
  await setDoc(doc(db, "lists", language), {
    [`unitsList`]: unitsList,
  });
  console.log("Italian List updated");
};

export const getItalianListsFirebase = async () => {
  const docRef = doc(db, "lists", "italianLists");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`post lists loaded`);
    return docSnap.data();
  } else {
    console.log("No post lists document");
  }
};

export const getPostBySlugFirebase = async (slug) => {
  const docRef = doc(db, "doubtsPor", slug);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`post ${slug} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};
